import { render, staticRenderFns } from "./productsItem.vue?vue&type=template&id=9a7f423a&scoped=true&"
import script from "./productsItem.vue?vue&type=script&lang=js&"
export * from "./productsItem.vue?vue&type=script&lang=js&"
import style0 from "./productsItem.vue?vue&type=style&index=0&id=9a7f423a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9a7f423a",
  null
  
)

export default component.exports